/*
 * This is global-styles.scss
 * NOTE: You shouldn't add new styles here, if needed create a new component file or use an existing one to add the styles.
 */
@use "sass:color";

@import "custom-bootstrap";
@import "functions";
@import "./scrollbar-styles";
@import "./plugin/angular-calendar";
@import "angular-calendar/scss/angular-calendar";
@import "bootstrap/scss/bootstrap-grid";
@import "bootstrap/scss/bootstrap-grid.scss";
@import "slick-carousel/slick/slick";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import "../../node_modules/@awesome.me/kit-ede68c049b/icons/scss/brands";
@import "../../node_modules/@awesome.me/kit-ede68c049b/icons/scss/regular";
@import "../../node_modules/@awesome.me/kit-ede68c049b/icons/scss/solid";
@import "../../node_modules/@awesome.me/kit-ede68c049b/icons/scss/light";
@import "../../node_modules/@awesome.me/kit-ede68c049b/icons/scss/thin";
@import "../../node_modules/@awesome.me/kit-ede68c049b/icons/scss/duotone";
@import "../../node_modules/@awesome.me/kit-ede68c049b/icons/scss/fontawesome";
@import "../../node_modules/@awesome.me/kit-ede68c049b/icons/scss/custom-icons";

@import "prevent-zoom-on-safari";

@import "./plugin/typeahead";

@import "./plugin/hubspot";

@import "./plugin/emoji-picker";

* {
	white-space: normal;
	overflow-wrap: anywhere;
	hyphens: auto;

	&:not(:disabled, .disabled):focus {
		outline: none;
		z-index: $z-index-focussed-item;
	}
}

[tabindex="-1"]:focus:not(:focus-visible) {
	// stylelint-disable-next-line declaration-no-important
	outline: 0 !important;
	box-shadow: none;
}

p-booking-plugin-preview {
	/* stylelint-disable-next-line no-descending-specificity */
	* {
		// stylelint-disable-next-line declaration-no-important
		overflow-wrap: initial !important;
		// stylelint-disable-next-line declaration-no-important
		hyphens: none !important;
	}
}

.progress-bar-striped {
	--bs-progress-height: 1rem;
}

p-shift-item,
p-all-day-item {
	float-ui-content {
		max-width: initial;
		/* stylelint-disable-next-line no-descending-specificity */
		* {
			max-width: initial;
		}
	}
}

ul.nav {
	overflow-wrap: initial;
	/* stylelint-disable-next-line no-descending-specificity */
	* {
		overflow-wrap: initial;
	}
}

:where(:not(:is(img, svg, .slick-track, p-now-line))) {
	max-width: 100%;
}

:where(.row) {
	max-width: calc(100% + 24px);
}

img {
	overflow: hidden;
	overflow-wrap: break-word;
	user-select: none;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	/* stylelint-disable-next-line no-descending-specificity */
	img {
		max-width: 100%;
	}
}

@import "./typography";

@import "./plugin/ngb-datepicker";

.sticky-headline {
	position: sticky;
	top: 0;
	z-index: $z-index-above-focussed-item;
}

.overflow-x-hidden {
	// stylelint-disable-next-line declaration-no-important
	overflow-x: hidden !important;
}

.overflow-x-clip {
	overflow-x: clip;
}

.max-width-600 {
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
}

@import "./plugin/tawk";

.dr-plano-face-wrapper {
	width: 100%;
	container-type: size;
	height: 100%;
	position: relative;
	overflow: hidden;
}

.dr-plano-face {
	max-width: 200px;
	width: min(100cqh, 45vmin);
	height: auto;
	opacity: 0.8;
	pointer-events: none;
	position: absolute;
	bottom: 0;

	--p-offset-face-x: 15%;
	--p-offset-face-y: 12%;
	&.face-left {
		left: 0;
		translate: calc(var(--p-offset-face-x) * -1) var(--p-offset-face-y);
	}
	&.face-right {
		right: 0;
		transform: scaleX(-1);
		translate: var(--p-offset-face-x) var(--p-offset-face-y);
	}

	@container (max-height: 110px) {
		display: none;
	}
}

p-ai-form-array {
	// add a z-index to every direct child that is not the add button to prevent overlapping issues
	& > :not(p-button) {
		position: relative;
		z-index: 1;
	}
}

@import "./bootstrap/buttons";
@import "./bootstrap/button-group";
@import "./bootstrap/form-control";
@import "./bootstrap/list-group";

@import "./plugin/pdf-viewer";

@import "./component/p-wizard";
@import "./component/fa-icon";
@import "./component/activity-list-item";
@import "./component/p-button";
@import "./component/p-progress";
@import "./component/leave-days-calendar-modal-box";
@import "./component/leave-days-summary";
@import "./component/p-dropdown";
@import "./component/p-work-rule-versions";
@import "./component/p-list";
@import "./component/p-threshold";
@import "./component/p-collapsible";
@import "./component/p-all-day-item.component";
@import "./component/p-report-row";
@import "./component/holiday-items-editable-modal-box";
@import "./component/holiday-labels-editable-modal-box";
@import "./bootstrap/tables";

[type="button"]:not(:disabled) {
	cursor: initial;
	&:not([aria-disabled="true"]) {
		cursor: pointer;
	}
}

button:disabled {
	color: inherit;
}

input {
	&[type="time"] {
		// The time input adds an icon that we don't want to display
		&::-webkit-calendar-picker-indicator {
			display: none;
		}
		// stylelint-disable-next-line declaration-no-important
		display: flex !important;
		align-items: center;
		&::-webkit-datetime-edit {
			display: flex;
			height: min-content;
			align-items: center;
			line-height: normal;
		}
	}
	border-radius: $border-radius;
	border: $border-width solid $border-color;

	// NOTE: Some browsers fill inputs with auto filled values with e.g. yellow. Urg.
	&:-webkit-autofill {
		box-shadow: 0 0 0 1000px $white inset;
	}
}

.has-danger {
	&.whole-input-wrapper,
	&.whole-input-wrapper.focus,
	&.whole-input-wrapper:focus,
	&.btn,
	.btn,
	.form-control,
	.form-control:focus,
	.form-control.focus {
		border-color: $danger;
		z-index: 10;
	}

	&.whole-input-wrapper.focus,
	&.whole-input-wrapper:focus {
		// stylelint-disable-next-line declaration-no-important
		box-shadow: 0 0 0 0.2rem rgba(179, 33, 43, 0.25) !important;
	}

	.form-control:focus,
	.form-control-read-mode:focus,
	.form-control.whole-input-wrapper.focus {
		box-shadow: 0 0 0 0.2rem rgba(179, 33, 43, 0.25);
	}
}

.required {
	.btn-group > .btn,
	&.btn {
		&:not(:disabled, .disabled) {
			// stylelint-disable-next-line declaration-no-important
			border-bottom-color: $danger !important;
		}
	}
}

/* TODO: Remove next line */
/* stylelint-disable no-descending-specificity */

.has-warning {
	.btn,
	.form-control:focus,
	.form-control.focus,
	.form-control {
		border-color: $warning;
	}

	.form-control:focus,
	.form-control.focus,
	.form-control-read-mode:focus {
		box-shadow: 0 0 0 0.2rem rgba(179, 33, 43, 0.25);
	}

	label {
		color: $warning;
	}
}

.form .form-control:not(.form-control-sm),
form .form-control:not(.form-control-sm),
form .form-control-read-mode,
form .btn:not(.btn-sm, .btn-lg, .btn-frameless, .p-checkbox-without-button-styles, blockquote, .dropdown-item-btn) {
	min-height: calc($form-control-min-height + 2px);
	height: auto;
}

.btn-sm,
.btn-group-sm > .btn {
	&.rounded-pill,
	&.rounded-start,
	&.rounded-end {
		border-radius: inherit;
	}
}

.btn {
	&.rounded-pill,
	&.rounded-start,
	&.rounded-end {
		border-radius: inherit;
	}
}

.input-group {
	& > .btn.btn-sm,
	&.input-group-sm .input-group.input-group-prepend {
		font-size: $font-size-sm;
	}

	&:not(.input-group-sm) > .input-group-prepend.input-group-text {
		font-size: $font-size-body;
	}
}

.input-group-prepend {
	&.input-group-text {
		color: $gray-600;
	}
	&[class*="input-group-"] {
		border-right-color: transparent;
	}
	&:not(button, .custom-input-group) fa-icon {
		color: darken($gray-400, 4%);
	}
}

.input-group {
	& .input-group-prepend {
		&:not(:first-child) *:not(p-member-badge) {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
	& > .input-group-prepend {
		& *:not(p-member-badge) {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
	& > .btn.btn-lg,
	&.input-group-lg .input-group-prepend {
		font-size: $font-size-lg-clamp;
	}
}

.btn-sm,
.btn-group-sm > .btn {
	&.rounded-pill,
	&.rounded-start,
	&.rounded-end {
		border-radius: inherit;
	}
}

.rounded-lg {
	// stylelint-disable-next-line declaration-no-important
	border-radius: map-get($spacers, 2) !important;
}
.rounded-xl {
	// stylelint-disable-next-line declaration-no-important
	border-radius: $border-radius-xl !important;
}
.rounded-end-0 {
	// stylelint-disable-next-line declaration-no-important
	border-top-right-radius: 0 !important;
	// stylelint-disable-next-line declaration-no-important
	border-bottom-right-radius: 0 !important;
}

.form-text,
.form-text-color {
	color: $gray-cold-600;
}

.pIcon {
	/* stylelint-disable-next-line sh-waqar/declaration-use-variable, declaration-no-important */
	font-weight: 900 !important;
}

.form-control {
	position: relative;
	height: auto;

	ul,
	ol {
		padding-left: map-get($spacers, 5);

		li {
			padding-left: map-get($spacers, 2);
		}
	}

	&:focus,
	&.focus {
		border-color: shift-color-level($primary, -4);

		&.btn-primary {
			background-color: shift-color-level($primary, 2);
			color: $white;
		}
	}
}

.form-control-read-mode {
	@extend .form-control;
	@extend .font-tabular-nums;
	@extend .bg-light;
	border-color: $light;
	@extend .d-flex;
	@extend .align-items-center;

	.bg-light & {
		// stylelint-disable-next-line declaration-no-important
		background-color: $gray-cold-100 !important;
	}
}

.h-fit-content {
	// stylelint-disable-next-line declaration-no-important
	height: fit-content !important;
}

@media (any-hover: hover) {
	.hover-hide-trashed:hover .has-avatar.is-trashed:not(.skeleton-animated) fa-icon {
		opacity: 0;
	}
}

.fa-li {
	// stylelint-disable-next-line declaration-no-important
	position: absolute !important;
}

p-section > div > *:not(.ignore-last-child-style):last-child {
	// stylelint-disable-next-line declaration-no-important
	margin-bottom: 0 !important;
}

p-modal-content-body > :last-child {
	margin-bottom: 0;
}

.form-group {
	margin-bottom: map-get($spacers, 3);
}

.angular-editor-textarea {
	// stylelint-disable-next-line declaration-no-important
	min-height: $p-angular-editor-min-height !important;
}

button[disabled],
button.disabled,
.btn.disabled {
	// NOTE: Bootstrap 5 added a pointer-events: none to all disabled buttons
	// this way we can't show the not-allowed cursor
	// stylelint-disable-next-line declaration-no-important
	pointer-events: auto !important;
}

.form-control:not(.whole-input-wrapper),
.btn:not(.ngb-dp-arrow-btn),
[pEditable] .card.pulse,
.dropdown-item,
button.card.bg-secondary {
	&.disabled,
	&:disabled,
	&[disabled] {
		/* stylelint-disable-next-line declaration-no-important */
		opacity: $disabled-opacity !important;
	}
}
/* stylelint-enable */

.input-hidden {
	.input-group-append {
		// stylelint-disable-next-line declaration-no-important
		border-top-left-radius: $btn-border-radius !important;
		// stylelint-disable-next-line declaration-no-important
		border-bottom-left-radius: $btn-border-radius !important;
	}
}

.input-group.disabled:not(.input-hidden) {
	.input-group-append:not(.cannot-set-hint, .dropdown-append) {
		background-color: $input-disabled-bg;
		color: rgba($input-color, $disabled-opacity);
		// stylelint-disable-next-line declaration-no-important
		opacity: $disabled-opacity !important;
	}
	.input-group-prepend {
		/* stylelint-disable-next-line declaration-no-important */
		opacity: $disabled-opacity !important;
	}
}

@-moz-document url-prefix() {
	// Firefox doesn't allow things to be rendered underneath the gutter
	// so we disable the scrollbar-gutter for such cases
	.content {
		// stylelint-disable-next-line declaration-no-important
		scrollbar-gutter: auto !important;
	}
	ngb-modal-window:not(.no-gutter-body) {
		.modal-body {
			// stylelint-disable-next-line declaration-no-important
			scrollbar-gutter: auto !important;
		}
	}
}

.badge {
	text-align: left;
	&.bg-light {
		color: $body-color;
	}
}

.text-secondary {
	// stylelint-disable-next-line declaration-no-important
	color: $gray-cold-900 !important;
}

@keyframes p-bird-pick {
	0% {
		rotate: 0deg;
	}
	77% {
		rotate: 0deg;
	}
	80% {
		rotate: 25deg;
	}
	82% {
		rotate: 10deg;
	}
	84% {
		rotate: 25deg;
	}
	87% {
		rotate: 0deg;
	}
}

.early-bird-icon.animated {
	& span {
		animation-name: p-bird-pick;
		animation-duration: 5s;
		transform-origin: bottom;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
	}
}

.skeleton {
	// stylelint-disable-next-line declaration-no-important
	background-color: $bg-skeleton !important;
	// stylelint-disable-next-line declaration-no-important
	border-color: transparent !important;
}

// Text should not be visible. This is like a placeholder for text. No animations here.
.text-skeleton {
	@extend .skeleton;
	cursor: default;
	position: relative;
	// stylelint-disable-next-line declaration-no-important
	color: transparent !important;
	// stylelint-disable-next-line declaration-no-important
	border-top-color: transparent !important;
	// stylelint-disable-next-line declaration-no-important
	border-left-color: transparent !important;
	// stylelint-disable-next-line declaration-no-important
	border-bottom-color: transparent !important;
	// stylelint-disable-next-line declaration-no-important
	border-right-color: transparent !important;
	background-repeat: no-repeat;
	background-size: 244px 104px;
	border-radius: 6px; /* stylelint-disable-line declaration-property-unit-disallowed-list */
	white-space: nowrap;

	@media (any-hover: hover) {
		&:hover {
			// stylelint-disable-next-line declaration-no-important
			color: transparent !important;
		}
	}

	/* stylelint-disable no-descending-specificity */
	> * {
		opacity: 0;
	}
	/* stylelint-enable */
}

.text-skeleton-animated {
	@extend .text-skeleton;
}

// Like text-skeleton but with animation
.text-skeleton-animated,
.skeleton-animated {
	cursor: wait;
	position: relative;
	// stylelint-disable-next-line declaration-no-important
	background-color: $bg-skeleton !important;
	overflow: hidden;
	&::before {
		position: absolute;
		top: 0;
		left: -100%;
		// Make sure the animated shimmer is on top of the element
		z-index: 1;
		display: block;
		content: "";
		animation-duration: 1s;
		animation-fill-mode: both;
		animation-iteration-count: infinite;
		animation-name: p-place-holder-shimmer;
		animation-timing-function: linear;
		width: 100%;
		height: 100%;
		background-image: linear-gradient(
			90deg,
			$bg-skeleton 0%,
			color.scale($bg-skeleton, $lightness: 43%) 50%,
			$bg-skeleton 100%
		);
	}
	/* stylelint-disable no-descending-specificity */
	& * {
		opacity: 0;
	}
	& fa-icon {
		opacity: 1;
		& * {
			opacity: 1;
		}
	}
	/* stylelint-enable no-descending-specificity */
}

@keyframes p-place-holder-shimmer {
	0% {
		left: -100%;
	}

	100% {
		left: 100%;
	}
}

.btn-link {
	color: shift-color-level($primary, 3);
}

.btn-frameless {
	margin: 0;
	border-radius: 0;
	border-width: 0;
}

.radius-0 {
	border-radius: 0;
}

.btn-group {
	/* stylelint-disable no-descending-specificity */
	.btn-primary:not(:first-child) {
		border-left-color: shift-color-level($primary, 3);
	}

	> p-button:not(:first-child) .btn,
	> p-dropdown:not(:last-child) .btn {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	> p-button:not(:last-child) .btn,
	> p-dropdown:not(:last-child) .btn {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	/*
	 * TODO: PLANO-186041
	 * Bootstrap doesn't really do a good job with the margins and z-indexes of the buttons in a button group.
	 * So here I am fixing it, by setting the correct z-index and adjusting the margins, so that the button to the right
	 * always has priority over the button to the left.
	 * Right now it works for button groups with up to 4 buttons. If you need more, you need to add more rules :)
	 */
	p-button + p-button,
	p-button + p-dropdown {
		&:nth-child(1) {
			z-index: 0;
		}

		&:nth-child(2) {
			.btn {
				/* stylelint-disable-next-line sh-waqar/declaration-use-variable */
				margin-left: -1px;
				z-index: 1;
			}
		}

		&:nth-child(3) {
			.btn {
				/* stylelint-disable-next-line sh-waqar/declaration-use-variable */
				margin-left: -2px;
				z-index: 2;
			}
		}

		&:nth-child(4) {
			.btn {
				/* stylelint-disable-next-line sh-waqar/declaration-use-variable */
				margin-left: -3px;
				z-index: 3;
			}
		}

		&:nth-child(5) {
			.btn {
				/* stylelint-disable-next-line sh-waqar/declaration-use-variable */
				margin-left: -4px;
				z-index: 4;
			}
		}

		&:nth-child(6) {
			.btn {
				/* stylelint-disable-next-line sh-waqar/declaration-use-variable */
				margin-left: -5px;
				z-index: 5;
			}
		}

		&:nth-child(7) {
			.btn {
				/* stylelint-disable-next-line sh-waqar/declaration-use-variable */
				margin-left: -6px;
				z-index: 6;
			}
		}
	}
	/* stylelint-enable */
}

@import "./directive/p-editable";

@import "./directive/icon-list";

#print-section {
	h3 {
		margin-top: $h3-font-size;
	}
}

/* stylelint-disable no-descending-specificity */
@media (any-hover: hover) {
	a,
	a[href],
	a[role="link"],
	a:not([href]).clickable,
	a:not([href]).btn,
	a.btn,
	button,
	.clickable {
		cursor: pointer;

		// TODO: PLANO-169089 Can this be removed? At least for the a tags. It should be kept, consider moving this/parts of it to _buttons.scss
		transition:
			background-color 0.3s ease-out,
			border-color 0.3s ease-out,
			color 0.3s ease-out;

		&.disabled,
		&[disabled] {
			// stylelint-disable-next-line declaration-no-important
			cursor: not-allowed !important;
		}

		:hover {
			// stylelint-disable-next-line declaration-no-important
			text-decoration: none !important;
		}
	}
}
/* stylelint-enable */

p-calendar-leaves-week-bar-item p-all-day-item {
	// stylelint-disable-next-line declaration-no-important
	background-color: transparent !important;
}

/* stylelint-disable no-descending-specificity */
textarea,
input {
	&.disabled,
	&[disabled] {
		cursor: not-allowed;
	}
}
/* stylelint-enable */

textarea {
	white-space: pre-wrap;
}

@import "./bootstrap/background";

.color-primary {
	color: $primary;
}

.content {
	display: flex;
	flex-direction: column;
}

.info-card {
	display: inline-block;
	padding: map-get($spacers, 2) map-get($spacers, 3);
	border-radius: 0.5rem;
	// stylelint-disable-next-line declaration-no-important
	background-color: $white !important;
	box-shadow: 0 5px 10px $darken-0125;
}

.grainy {
	position: relative;
	overflow: hidden;
	&::after {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		display: block;
		width: 100%;
		opacity: 0.45;
		content: "";
		mix-blend-mode: plus-lighter;
		filter: brightness(2);
		//cSpell:ignore Crect Csvg
		background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 400 400' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='9.68' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
	}
}

.w-fit-content {
	width: fit-content;
}

a.navigate-inside-app {
	// stylelint-disable-next-line declaration-no-important
	color: inherit !important;
	// stylelint-disable-next-line declaration-no-important
	text-decoration: none !important;
}

// TODO: PLANO-187884 Decide how to continue with .card-options
a.btn.card-option {
	display: flex;
	align-items: center;
	justify-content: center;
}

@import "./bootstrap/card";

.list-headline-bar {
	display: flex;
	align-items: center;

	// Next line is important so .transactions-list-headlines gets the same width as .transactions-list-tupel
	// both of them are query containers and need the same width so the children behave the same
	// stylelint-disable-next-line declaration-property-unit-disallowed-list
	border: 1px solid transparent;
	margin-bottom: map-get($spacers, 2);
	background-color: $gray-cold-200;

	@extend .card-options;
	@extend .flex-row;
	@extend .rounded;
}

.crop-on-overflow {
	small {
		vertical-align: top;
	}
}

// TODO: PLANO-187884 Decide how to continue with .card-options
li.card-options,
.card-options.card-header {
	.card-option {
		border-bottom: 0;
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;

		&:first-child {
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
}

.card[pEditable] {
	background: $gray-200;
}

section:not(.modal-body, :last-child) {
	border-bottom: $card-border-width solid $modal-footer-border-color;
}

.transparent {
	opacity: 0;
	user-select: none;
}

@import "./bootstrap/modal";
@import "./plugin/popper-content";
@import "./plugin/bs-tooltip";

// For bootstrap tooltips
[triggers="click"] {
	cursor: pointer;
}

.tooltip,
.popover {
	z-index: $z-index-tooltip;
}

.tooltip {
	font-size: $font-size-sm;

	&.show {
		opacity: 1;
	}

	.tooltip-inner {
		background-color: $white;
		border-radius: map-get($spacers, 2);
		color: inherit;
		padding: 0;
		text-align: initial;
	}
}

@mixin alert-theme-bg($color) {
	background: linear-gradient(
		146deg,
		color.scale($color, $lightness: -13%) 0%,
		color.scale($color, $lightness: 3%) 30%,
		color.scale($color, $lightness: 13%) 100%
	);
}

@import "./bootstrap/toast";
@import "./bootstrap/alert";

@import "./plugin/bs-dropdown";
@import "./bootstrap/dropdown";

/* stylelint-disable-next-line no-descending-specificity */
.dropdown-item {
	padding: $input-btn-padding-y $input-btn-padding-x;

	&:active,
	&.active {
		color: $white;
		// stylelint-disable-next-line declaration-no-important
		background-color: $primary !important;
	}

	> li > .dropdown-item {
		padding: $input-btn-padding-y $input-btn-padding-x;
	}
}

@import "./bootstrap/nav";

@include media-breakpoint-up(lg) {
	.fa-rotate-md-0 {
		// stylelint-disable-next-line declaration-no-important
		transform: rotate(0) !important;
	}
}

/**
 * [START]
 * Wiggle
 */

.wiggle {
	animation: p-wiggle 2s linear;
	z-index: 1000;
}

// Keyframes
@keyframes p-wiggle {
	0%,
	7% {
		transform: rotate(0);
	}

	10% {
		transform: rotate(4deg);
	}

	15% {
		transform: rotate(-6deg);
	}

	20% {
		transform: rotate(10deg);
	}

	25% {
		transform: rotate(-10deg);
	}

	30% {
		transform: rotate(6deg);
	}

	35% {
		transform: rotate(-4deg);
	}

	40%,
	100% {
		transform: rotate(0);
	}
}

/**
 * [END]
 * Wiggle
 */

.heart-beat {
	animation-name: p-heart-beat;
	animation-duration: 2s;
	animation-delay: 10s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}

@keyframes p-heart-beat {
	0% {
		transform: scale(1);
	}
	10% {
		transform: scale(1.2);
	}
	15% {
		transform: scale(1);
	}
	20% {
		transform: scale(1.3);
	}
	50% {
		transform: scale(1);
	}
	100% {
		transform: scale(1);
	}
}

@each $color, $value in $theme-colors {
	.striped-#{$color} {
		background: striped($color);
		background-color: $value;
		background-size: 18px 18px;
		transition: background 0.3s ease-out;

		@media (any-hover: hover) {
			&:hover {
				z-index: 1; /* Ensures the border of the hovered element is visible even if, e.g., two list items are glued together (like we have on sales > transactions). */
				color: $body-color;
				// stylelint-disable-next-line declaration-no-important
				border-color: $value !important;

				--bs-btn-hover-border-color: #{$value};
				--bs-btn-hover-color: #{$body-color};
			}
		}
		&:active {
			color: $body-color;
			// stylelint-disable-next-line declaration-no-important
			border-color: $value !important;
		}
	}
}

.modal-dialog-centered {
	justify-content: center;
}

li a.list-group-item {
	border: 0; // FIXME: this is hacky! find better solution
	background-color: inherit;
}

.list-group-item-mobile {
	color: $input-color;
	text-align: inherit;
	// stylelint-disable-next-line declaration-no-important
	cursor: default !important;
	width: 100%;
}

.list-group-item-action {
	cursor: pointer;

	&.disabled,
	&[disabled] {
		cursor: not-allowed;
	}

	&:not(.striped-primary):hover {
		// NOTE: I tried this. It looked nice in the quick-assignment modal. But not in the members-list in the
		// main-sidebar
		// color: $white;
		// background: $secondary;

		background: $gray-200;
	}

	/* stylelint-disable-next-line no-descending-specificity */
	&:active,
	&.active {
		.badge {
			// stylelint-disable-next-line declaration-no-important
			border-color: shift-color-level($primary, 3) !important;
			transition: border-color 0.5s ease-out;
		}
		--bs-btn-hover-color: #{$white};
		--bs-btn-hover-bg: #{$primary};
		// stylelint-disable-next-line declaration-no-important
		background-color: $primary !important;
		// stylelint-disable-next-line declaration-no-important
		color: $white !important;

		@media (any-hover: hover) {
			&:hover {
				background: shift-color-level($primary, -2);
				--bs-btn-hover-bg: #{shift-color-level($primary, -2)};

				.badge {
					// stylelint-disable-next-line declaration-no-important
					border-color: shift-color-level($primary, 1) !important;
				}
			}
		}
	}
}

.text-transform-none {
	// stylelint-disable-next-line declaration-no-important
	text-transform: none !important;
}

.badge.disabled {
	opacity: 0.4;
}

.action-box-wrapper {
	min-height: 1rem;
	position: relative;
	flex-grow: 1;
	display: flex;
	align-items: center;
	user-select: text;
	text-align: left;
	text-transform: none;
	.action-indicator-wrapper {
		position: absolute;
		top: 4px;
		right: 6px;
		z-index: 2;
		align-self: flex-start;
		font-size: $font-size-sm;
	}
}

.badge.bg-purple {
	color: $white;
	background-color: $purple;
}

.badge.bg-primary {
	color: $white;
}

.badge.bg-warning {
	color: $gray-cold-900;
}

.badge-white {
	background-color: $white;
	border-color: $white;
}

.bg-light {
	background-color: shift-color-level($light, 1);
	&:not(.form-control-read-mode) {
		border-color: shift-color-level($light, 2);
	}
	&.badge {
		// stylelint-disable-next-line declaration-no-important
		background-color: shift-color-level($light, 1) !important;
	}
}

.list-group-item.active {
	// stylelint-disable-next-line declaration-no-important
	color: $white !important;
}

html,
body {
	/* Fix multiple-scroll-issue on ios */
	height: 100vh;
	// stylelint-disable-next-line declaration-block-no-duplicate-properties
	height: 100svh;
	overflow: hidden;
	max-width: 100%;
	position: relative;
}

body .d-flex[hidden],
body .d-block[hidden],
[hidden] {
	// stylelint-disable-next-line declaration-no-important
	display: none !important;
}

.no-overflow-wrap {
	overflow-wrap: initial;
	hyphens: none;
	/* stylelint-disable-next-line no-descending-specificity */
	* {
		overflow-wrap: initial;
		hyphens: none;
	}
}

.min-w-max-content {
	min-width: max-content;
}

.min-w-fit-content {
	min-width: fit-content;
}

.min-w-150 {
	// stylelint-disable-next-line declaration-no-important
	min-width: 150px !important;
}

.min-w-140 {
	// stylelint-disable-next-line declaration-no-important
	min-width: 140px !important;
}

.min-w-md-140 {
	@include media-breakpoint-up(sm) {
		// stylelint-disable-next-line declaration-no-important
		min-width: 140px !important;
	}
}

.min-w-lg-140 {
	@include media-breakpoint-up(lg) {
		// stylelint-disable-next-line declaration-no-important
		min-width: 140px !important;
	}
}

.min-w-120 {
	// stylelint-disable-next-line declaration-no-important
	min-width: 120px !important;
}

.min-w-md-120 {
	@include media-breakpoint-up(sm) {
		// stylelint-disable-next-line declaration-no-important
		min-width: 120px !important;
	}
}

.min-w-lg-120 {
	@include media-breakpoint-up(md) {
		// stylelint-disable-next-line declaration-no-important
		min-width: 120px !important;
	}
}

.min-w-md-100 {
	@include media-breakpoint-up(sm) {
		// stylelint-disable-next-line declaration-no-important
		min-width: 100px !important;
	}
}

.min-w-90 {
	// stylelint-disable-next-line declaration-no-important
	min-width: 90px !important;
}

.min-w-md-90 {
	@include media-breakpoint-up(sm) {
		// stylelint-disable-next-line declaration-no-important
		min-width: 90px !important;
	}
}

.min-w-80 {
	// stylelint-disable-next-line declaration-no-important
	min-width: 80px !important;
}

.min-w-70 {
	// stylelint-disable-next-line declaration-no-important
	min-width: 70px !important;
}

.min-w-60 {
	// stylelint-disable-next-line declaration-no-important
	min-width: 60px !important;
}

.min-w-md-60 {
	@include media-breakpoint-up(sm) {
		// stylelint-disable-next-line declaration-no-important
		min-width: 60px !important;
	}
}

.min-w-lg-60 {
	@include media-breakpoint-up(md) {
		// stylelint-disable-next-line declaration-no-important
		min-width: 60px !important;
	}
}

.min-w-0 {
	min-width: 0;
}

.min-w-50 {
	// stylelint-disable-next-line declaration-no-important
	min-width: 50px !important;
}

.list-group-flush:last-child .list-group-item:last-child {
	border-bottom-width: $border-width;
}

.list-unstyled {
	list-style: none;
}

.bg-muted {
	// stylelint-disable-next-line declaration-no-important
	background-color: $gray-cold-300 !important;
}

.wishes {
	z-index: 1;
	padding-inline: map-get($spacers, 1);
	align-self: center;
}

.h-100 {
	// Fix Firefox Issue https://drplano.atlassian.net/browse/PLANO-99310?focusedCommentId=25117
	min-height: 0;
}

// NOTE: this is a hack to make bootstrap 4.0.0 work with plano. remove it and fix all pEditables
.input-group-btn {
	align-items: stretch;
}

/*
 * HACK: on the ios native app, if the input had a font-size smaller than
 * 16px it automatically zooms into the content, which is a behavior we don't want.
 * So now we have this style which increases the font-size but reduces the padding and the
 * height so the appearance of the element stays the same (except for the font-size)
 */
input.form-control-sm.is-ios-app {
	font-size: $font-size-body;
	height: calc(1.5em + 0.3rem + 2px);
	/* stylelint-disable-next-line sh-waqar/declaration-use-variable */
	padding: 0.25rem 0.3rem;
}

/* stylelint-disable-next-line no-descending-specificity */
.form-control-read-mode,
.form-control-read-only {
	/* stylelint-disable-next-line no-descending-specificity */
	& .input-group-prepend {
		// stylelint-disable-next-line declaration-no-important
		margin-right: 0 !important;
		cursor: not-allowed;
	}
	input {
		// stylelint-disable-next-line declaration-no-important
		background-color: $input-disabled-bg !important;
	}
	&.form-control-read-only .input-group-text {
		// stylelint-disable-next-line declaration-no-important
		background-color: $input-disabled-bg !important;
	}
	&.form-control-read-mode .input-group-text {
		// stylelint-disable-next-line declaration-no-important
		background-color: $input-disabled-bg !important;
	}
}

input[readonly] {
	// stylelint-disable-next-line declaration-no-important
	background-color: $input-disabled-bg !important;
}

.form-control.whole-input-wrapper.disabled {
	border-color: rgba($border-color, 0.6);
}

p-ai-switch {
	/* stylelint-disable-next-line no-descending-specificity */
	.input-group-append,
	.input-group-prepend {
		border-radius: $btn-border-radius;
		/* stylelint-disable-next-line no-descending-specificity */
		*:not(.btn-frameless) {
			border-top-left-radius: inherit;
			border-top-right-radius: inherit;
			border-bottom-left-radius: inherit;
			border-bottom-right-radius: inherit;
		}
	}
}

/* stylelint-disable no-descending-specificity */
/* stylelint-disable declaration-no-important -- Bootstraps style is very specific here. But their details aren’t important for us here. */
.input-group {
	.input-group-append {
		min-width: fit-content;
	}

	input {
		min-width: 50px !important;
		&.has-non-interactive-prepend {
			padding-left: map-get($spacers, 2);
		}
		&.has-append {
			border-top-right-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
		}
		&:not(.rounded-end) {
			margin-right: 0 !important;
		}
	}

	.whole-input-wrapper {
		display: flex;
		align-items: stretch !important;
		padding: 0;
		min-width: 100px;
		font-family: $font-family-base;
		// cSpell:ignore peditabledismissbutton peditablesuccessbutton
		& [class*="input-group-"]:not([peditabledismissbutton], [peditablesuccessbutton], .custom-input-group) {
			border: none;
			box-shadow: none !important;
		}
		& input {
			border-left: none;
			border-top: none;
			border-bottom: none;
			box-shadow: none !important;
			&:not(.p-editable-active, :read-only) {
				// TODO: PLANO-165618 Probably obsolete
				border-right-color: transparent;
			}
			border-top-right-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
			&:not(:focus) {
				// TODO: PLANO-165618 Probably obsolete
				border-right-width: $p-editable-border-right-width - 1 !important;
			}
		}
		&.focus {
			box-shadow: $input-focus-box-shadow;
			z-index: 10;
		}
		.input-group-append {
			margin-left: 0 !important;
			&:last-of-type:not(.cannot-set-hint) > * {
				border-right-color: transparent;
				border-bottom-color: transparent;
			}
		}
		&.disabled {
			input.disabled {
				border-right-color: $gray-cold-100;
			}
		}

		.appended-editable-buttons .btn {
			border-top: none;
			border-bottom: none;
			border-left: none;
		}
	}

	&:not(.disabled, :disabled, [disabled]) .input-group-prepend:not(button) {
		background-color: transparent;
	}

	&.disabled,
	&:disabled,
	&[disabled] {
		input,
		.form-control:not(.whole-input-wrapper),
		.form-control-read-mode:not(.whole-input-wrapper) {
			background-color: $gray-cold-100 !important;
		}
		.form-control-read-mode {
			border-color: $gray-cold-100;
		}
	}

	&.disabled {
		.input-group-text {
			border-color: transparent;
		}
	}

	&:not(.has-wrapped-appended) {
		input.has-non-interactive-append {
			margin-right: $border-width * -1;
		}
		.whole-input-wrapper {
			.input-group-append:not(.cannot-set-hint) {
				&:last-of-type > * {
					border-top-color: transparent;
				}
			}
		}
	}

	&.has-wrapped-appended {
		input,
		.whole-input-wrapper.has-interactive-append {
			&:not(.editable-buttons-visible) {
				border-top-right-radius: $card-border-radius !important;
			}
			border-bottom-left-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
		}

		.appended-editable-buttons {
			.btn:last-of-type {
				border-top-right-radius: $card-border-radius;
			}
		}

		.input-group-prepend {
			&[class^="input-group-"] {
				border-bottom-left-radius: 0 !important;
			}
		}
		.input-group-append {
			&:last-child,
			&:not(.appended-editable-buttons).last-element-on-line {
				flex-grow: 1;
				[class*="input-group-"] {
					flex-grow: 1;
				}
			}
			&.first-element-on-line {
				margin-left: 0 !important;
			}
			&.append-on-first-line {
				&.last-element-on-line {
					border-top-right-radius: $card-border-radius !important;
				}
			}
			&.append-on-last-line {
				&.first-element-on-line {
					border-top-left-radius: 0 !important;
					border-bottom-left-radius: $card-border-radius !important;
					/* stylelint-disable-next-line max-nesting-depth */
					& * {
						border-top-left-radius: 0 !important;
						border-bottom-left-radius: $card-border-radius !important;
					}
				}
				& :not(fa-icon):first-child {
					flex-grow: 1;
				}
				& * {
					border-radius: 0;
				}
				&.last-element-on-line {
					border-top-right-radius: 0 !important;
					border-bottom-right-radius: $card-border-radius !important;
					/* stylelint-disable-next-line max-nesting-depth */
					& * {
						border-top-right-radius: 0 !important;
						border-bottom-right-radius: $card-border-radius !important;
					}
				}
			}
			margin-left: 0;
			&:not(.appended-editable-buttons) {
				margin-top: $border-width * -1;
			}
		}
	}
}

/* stylelint-enable declaration-no-important */
/* stylelint-enable no-descending-specificity */

.container-sm {
	@include make-container();
	// stylelint-disable-next-line declaration-no-important
	max-width: 800px !important;
}

p-public .container {
	padding-top: map-get($spacers, 5);
}

@import "./bootstrap/containers";

.pb-tawk {
	&.container,
	&.container-sm,
	&.container-md,
	&.container-lg,
	&.container-xl,
	&.container-xxl {
		padding-top: map-get($spacers, 4);

		@include media-breakpoint-down(md) {
			padding-top: map-get($spacers, 2);
		}
	}
}

.is-mobile {
	& .content {
		// stylelint-disable-next-line declaration-no-important
		scrollbar-gutter: auto !important;
	}
}

.empty-badges-stack {
	flex-shrink: 0;
	display: flex;
}

@keyframes p-fade-in {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@import "./plugin/_angular-calendar.scss";

p-calendar {
	.cal-month-view {
		.cal-day-cell:not(:last-child) {
			// stylelint-disable-next-line declaration-no-important
			border-right: 0 !important;
		}
	}

	p-calendar-month-view.show-border-between-days {
		.cal-cell:not(:first-of-type) {
			position: relative;
			&::before {
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				content: "";
				height: 100%;
				width: 0;
				border-left: $border-width solid $border-color;
			}
		}
		p-all-day-item:has(.item-start) {
			/* stylelint-disable-next-line declaration-no-important -- Need to, otherwise it overlaps the border */
			left: $border-width !important;
		}
	}

	.timeline-separator {
		display: flex;
		align-items: center;
		height: 5vh;
		// stylelint-disable-next-line declaration-block-no-duplicate-properties
		height: 5svh;
		min-height: 40px;

		box-sizing: border-box;
		padding-top: map-get($spacers, 4);
		padding-bottom: 0;
		/* stylelint-disable-next-line declaration-property-unit-disallowed-list */
		border-bottom: 2px solid rgba(0, 0, 0, 0.07);

		/* stylelint-disable-next-line no-descending-specificity */
		span {
			position: relative;
			top: 50%;
			background: white;
			padding-left: map-get($spacers, 3);
			padding-right: map-get($spacers, 2);
		}
	}

	.cal-cell.cal-past .timeline-separator span {
		background: $gray-100;
	}
}

// cspell:ignore navbars
.cal-navbars-padding {
	/* stylelint-disable-next-line sh-waqar/declaration-use-variable -- we don't have a variable for this value. */
	padding-block: 0.35rem;
	padding-inline: map-get($spacers, 2);

	@include media-breakpoint-up(lg) {
		padding-inline: map-get($spacers, 3);
	}
}

/* On the main calendar page (scheduling), the calendar has "scrollbar-gutter: stable". Therefore, we need extra padding here for perfect alignment with the calendar below. */

p-scheduling {
	& p-calendar-month-weekdays-bar button:last-of-type {
		/* As in the month view, the last weekday in the bar is a button, we can use the variables for better maintenance. */
		/* stylelint-disable-next-line sh-waqar/declaration-use-variable  -- incorrect warning */
		padding-right: calc($p-dr-plano-scrollbar-width + $btn-padding-x);
	}

	/* stylelint-disable-next-line no-descending-specificity -- incorrect warning */
	& p-calendar-weekdays-bar .cal-day-cell:last-of-type {
		// /* stylelint-disable-next-line sh-waqar/declaration-use-variable */
		padding-right: $p-dr-plano-scrollbar-width;
	}
}

.progress-bar {
	transition: width 0.1s;
}

.shadow-hover,
.shadow-hover-sm,
.shadow-hover-lg {
	transition: box-shadow 0.5s;
}

.shadow-hover:hover {
	@extend .shadow;
}

.shadow-hover-sm:hover {
	@extend .shadow-sm;
}

.shadow-hover-lg:hover {
	@extend .shadow-lg;
}

/* stylelint-disable no-descending-specificity */
.form-control.required.ng-untouched.ng-p-invalid,
[required].ng-untouched.ng-p-invalid,
.form-control-less .required:not(.checked),
.ng-untouched.ng-p-invalid .required,
.form-control.required.ng-untouched.ng-invalid,
[required].ng-untouched.ng-invalid,
.ng-untouched.ng-invalid .required,
.ng-untouched.ng-invalid .required > .btn {
	// stylelint-disable-next-line declaration-no-important
	border-bottom-color: $danger !important;
}
/* stylelint-enable no-descending-specificity */

@media (max-width: 1400px) {
	.hidden-xlg-down {
		// stylelint-disable-next-line declaration-no-important
		display: none !important;
	}
}

.infinite-pulse {
	animation: p-infinite-pulse 5s ease-in-out infinite;
}

@keyframes p-infinite-pulse {
	0% {
		color: $danger;
	}

	35% {
		color: $success;
	}

	45% {
		color: $success;
	}

	80% {
		color: $warning;
	}

	100% {
		color: $danger;
	}
}

@media (any-hover: hover) {
	:where([role="listitem"]):not(.no-list-item-hover, .list-group-item-action) {
		/* We add a pseudo-element positioned absolute on top of the hovered element. The pseudo-element get's also a background color on hover. By doing so, we avoid overwriting the elements' background color and get consistent hover effects on all list items. */
		&::before {
			content: "";
			position: absolute;
			inset: 0;
			pointer-events: none; /* Ensures the overlay does not interfere with pointer events. */
		}

		/* On hover, we set the position of the element to "relative" to ensure the absolute positioned pseudo element gets the correct relation for the positioning. */
		&:hover {
			position: relative;

			&::before {
				/* In some cases, this is necessary to ensure the overlay is on top of the element and its children. On the other hand, in some rare cases, the higher z-index overlays, e.g., input fields. In those cases, one can easily use the custom property to change the z-index. */
				z-index: var(--p-list-item-hover-effect-z-index, 1);
				background: $gray-cold-150;
				mix-blend-mode: multiply; /* Ensuring the overlay doesn't effect texts etc. */
				border-radius: inherit;
			}
		}
	}
}

.row-gap-1 {
	row-gap: map-get($spacers, 1);
}

.col-gap-1 {
	column-gap: map-get($spacers, 1);
}

.row-gap-2 {
	row-gap: map-get($spacers, 2);
}

.col-gap-2 {
	column-gap: map-get($spacers, 2);
}

.row-gap-3 {
	row-gap: map-get($spacers, 3);
}

.col-gap-3 {
	column-gap: map-get($spacers, 3);
}

.row-gap-4 {
	row-gap: map-get($spacers, 4);
}

.col-gap-4 {
	column-gap: map-get($spacers, 4);
}

.row-gap-5 {
	row-gap: map-get($spacers, 5);
}

.col-gap-5 {
	column-gap: map-get($spacers, 5);
}

.list-with-li-spacers > li:not(:last-of-type) {
	/* stylelint-disable-next-line sh-waqar/declaration-use-variable -- Currently, we have no spacers with em values */
	margin-bottom: 0.5em;
}

.muted-item {
	opacity: $muted-item-opacity;
	transition: opacity 0.4s;
}

.is-in-the-past:not(.muted-item) {
	opacity: 0.5;
}

@media (any-hover: hover) {
	.unmuted-item {
		opacity: 1;
	}

	.muted-item:not(.disable-muted-item-hover):hover {
		opacity: 1;
	}
}

.muted {
	opacity: $muted-opacity;
}

.text-muted {
	// stylelint-disable-next-line declaration-no-important
	color: inherit !important;
	opacity: $muted-opacity;
}

.list-search-group {
	display: flex;
	flex-direction: column;
	/* stylelint-disable-next-line no-descending-specificity */
	& > * {
		width: 100%;

		@include media-breakpoint-up(xl) {
			max-width: 400px;
		}
	}
}

// For html code which is shown in emails and booking plugin
// we want to do margins by using <br> because this is more transparent to the user.
// So, remove default margins. See also course email layout and css of booking-plugin.
.email-and-booking-plugin-styles {
	ul,
	ol {
		// stylelint-disable-next-line declaration-no-important
		margin-top: 0 !important;
		// stylelint-disable-next-line declaration-no-important
		margin-bottom: 0 !important;
	}
}

#functions {
	#functions-carousel-nav {
		img {
			border-radius: 100%;
		}

		// make sure the nav images are not cut off
		.slick-list {
			overflow: visible;
		}

		// position all nav images horizontally with same distance between each other
		.slick-track {
			// stylelint-disable-next-line declaration-no-important
			width: auto !important;

			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			gap: map-get($spacers, 3);

			// Container for .slick-slide elements
			container-type: inline-size;
			container-name: functions-carousel-nav;
		}

		.slick-slide {
			// We don’t want nav to have transform animations.
			// So, we set "fade = true" during initialization. But, now we have to "fix" layout.
			// stylelint-disable-next-line declaration-no-important
			opacity: 1 !important;
			// stylelint-disable-next-line declaration-no-important
			left: auto !important;
			// stylelint-disable-next-line declaration-no-important
			width: auto !important;

			@container functions-carousel-nav (max-width: 610px) {
				max-width: 50px;
			}
		}

		img {
			cursor: pointer;
			// stylelint-disable-next-line declaration-no-important
			display: block !important;
			margin-inline: auto;
			// stylelint-disable-next-line declaration-no-important
			max-width: 60px !important;
		}
	}

	#functions-carousel {
		h3 {
			color: $primary;
			text-align: center;
			/* stylelint-disable-next-line sh-waqar/declaration-use-variable */
			margin: 2.5em 0 1.3em 0;
			max-width: 100%;
			text-transform: none;
		}

		ul {
			@media (max-width: 1370px) {
				/* stylelint-disable-next-line sh-waqar/declaration-use-variable, declaration-no-important */
				font-size: 1.1rem !important;
			}

			@media (max-width: 360px) {
				/* stylelint-disable-next-line sh-waqar/declaration-use-variable */
				padding-left: 18px;
			}
		}

		li {
			margin-bottom: $font-size-xxs;

			@media (max-width: 360px) {
				/* stylelint-disable-next-line sh-waqar/declaration-use-variable */
				margin-bottom: 0.3rem;
			}
		}
	}

	/* stylelint-disable-next-line no-descending-specificity */
	.slick-slide {
		display: flex;
		flex-direction: column;
		align-items: center;

		> div {
			max-width: 100%;
		}
	}

	.functions-carousel-text {
		margin-top: map-get($spacers, 3);
		font-size: $font-size-lg-clamp;
	}
}

#functions-video-carousel-nav {
	// nav images
	/* stylelint-disable-next-line no-descending-specificity */
	.slick-list {
		box-shadow:
			6px 0 4px -4px rgba(0, 0, 0, 0.3),
			-6px 0 4px -4px rgba(0, 0, 0, 0.3);
		margin-bottom: map-get($spacers, 3);

		/* stylelint-disable-next-line no-descending-specificity */
		.slick-slide:not(.slick-current) {
			/* stylelint-disable-next-line no-descending-specificity */
			img {
				filter: brightness(70%);
			}
		}

		/* stylelint-disable-next-line no-descending-specificity */
		img {
			cursor: pointer;
			// stylelint-disable-next-line declaration-no-important
			width: 160px !important;
			// stylelint-disable-next-line declaration-no-important
			height: 90px !important;

			/* stylelint-disable-next-line sh-waqar/declaration-use-variable */
			margin: 0 20px;

			/* stylelint-disable-next-line declaration-property-unit-disallowed-list */
			border: 2px solid $black;
		}

		@include media-breakpoint-down(md) {
			// stylelint-disable-next-line declaration-no-important
			display: none !important;
		}
	}
}

@import "./plugin/slick";

#clients-carousel {
	.clients-carousel-slide {
		max-height: 65vh;
		// stylelint-disable-next-line declaration-block-no-duplicate-properties
		max-height: 65svh;
		// stylelint-disable-next-line declaration-no-important
		display: flex !important;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
		row-gap: map-get($spacers, 4);
	}

	.slick-dots {
		margin-top: map-get($spacers, 5);
	}

	a {
		display: block;
	}

	/* stylelint-disable-next-line no-descending-specificity */
	img {
		height: 15vh;
		// stylelint-disable-next-line declaration-block-no-duplicate-properties
		height: 15svh;
		max-width: 100%;
	}

	// first item in list should not have top margin
	/* stylelint-disable-next-line no-descending-specificity */
	a:first-child img {
		margin-top: 0;
	}

	.studiobloc_pfungstadt {
		height: 13vh;
		// stylelint-disable-next-line declaration-block-no-duplicate-properties
		height: 13svh;
	}

	.boulderwelt {
		height: 11vh;
		// stylelint-disable-next-line declaration-block-no-duplicate-properties
		height: 11svh;
	}

	.mandala_dresden {
		height: 20vh;
		// stylelint-disable-next-line declaration-block-no-duplicate-properties
		height: 20svh;
	}

	.nordwandhalle_hamburg {
		height: 12vh;
		// stylelint-disable-next-line declaration-block-no-duplicate-properties
		height: 12svh;
	}

	.e4_nuernberg {
		height: 15vh;
		// stylelint-disable-next-line declaration-block-no-duplicate-properties
		height: 15svh;
	}

	.dav_rockerei_stuttgart {
		height: 9vh;
		// stylelint-disable-next-line declaration-block-no-duplicate-properties
		height: 9svh;
	}

	.bergstation_hilden {
		height: 12vh;
		// stylelint-disable-next-line declaration-block-no-duplicate-properties
		height: 12svh;
	}

	.element_boulders {
		height: 7vh;
		// stylelint-disable-next-line declaration-block-no-duplicate-properties
		height: 7svh;
	}

	.escaladrome_hannover {
		height: 7vh;
		// stylelint-disable-next-line declaration-block-no-duplicate-properties
		height: 7svh;
	}

	.dav_berchtesgaden_und_augsburg {
		height: 11vh;
		// stylelint-disable-next-line declaration-block-no-duplicate-properties
		height: 11svh;
	}

	.dav_hamburg {
		height: 14vh;
		// stylelint-disable-next-line declaration-block-no-duplicate-properties
		height: 14svh;
	}

	.urbanapes {
		height: 18vh;
		// stylelint-disable-next-line declaration-block-no-duplicate-properties
		height: 18svh;
	}

	.magic_mountain {
		height: 6vh;
		// stylelint-disable-next-line declaration-block-no-duplicate-properties
		height: 6svh;
	}

	.der_steinbock_boulderhalle {
		height: 13vh;
		// stylelint-disable-next-line declaration-block-no-duplicate-properties
		height: 13svh;
	}

	.berta_block_berlin {
		height: 18vh;
		// stylelint-disable-next-line declaration-block-no-duplicate-properties
		height: 18svh;
	}

	.boulderbar_salzburg {
		height: 13vh;
		// stylelint-disable-next-line declaration-block-no-duplicate-properties
		height: 13svh;
	}

	.stuntwerk_koeln {
		height: 15vh;
		// stylelint-disable-next-line declaration-block-no-duplicate-properties
		height: 15svh;
	}

	.block_helden {
		height: 17vh;
		// stylelint-disable-next-line declaration-block-no-duplicate-properties
		height: 17svh;
	}
}

.badges-stack {
	display: flex;
	align-items: center;

	> .badges-stack-item {
		/* stylelint-disable-next-line sh-waqar/declaration-use-variable, declaration-no-important */
		margin-right: -8px !important;

		&:last-child {
			// The last child shouldn't have the negative margin but the usual margin of not-stacked badges.
			// stylelint-disable-next-line declaration-no-important
			margin-right: $border-width * 2 !important;
		}
	}
}

.selected {
	// stylelint-disable-next-line declaration-no-important
	border-color: shift-color-level($primary, -5) !important;
	/* If you change the background-color for the selected state, also change other related places by searching for the declaration in the entire project. */
	// stylelint-disable-next-line declaration-no-important
	background: shift-color-level($primary, -10) !important;
	color: $body-color;
	transition: background-color 0.3s;

	@media (any-hover: hover) {
		&.clickable:hover,
		&.btn:hover {
			border-color: shift-color-level($primary, 0);
			// stylelint-disable-next-line declaration-no-important
			background: shift-color-level($primary, -5) !important;
		}
	}
}

p-shift-exchange-list-item {
	.col-offer {
		flex-shrink: 0;
	}

	.col-lastUpdate {
		flex-shrink: 0;
	}
}

p-shift-exchange-list {
	.col-offer {
		flex-grow: 0;
		flex-shrink: 1;

		@nest .not-detailed > & {
			flex-grow: 1;
			flex-shrink: 1;
			justify-content: space-between;

			p-shifts-info {
				overflow: hidden;
			}
		}

		@nest .detailed > & {
			flex-grow: 0;
			flex-shrink: 1;
			flex-basis: 360px;

			p-shifts-info {
				min-width: 210px;
			}
		}
	}

	.col-lastUpdate {
		flex-grow: 0;
		flex-shrink: 0;
		flex-basis: 4.5rem;
	}
}

p-shift-exchange-list,
p-shift-exchange-list-item {
	.col-offer {
		@extend .crop-on-overflow;
		align-self: stretch;
		display: flex;
		align-items: center;
	}

	.col-deadline {
		margin-right: map-get($spacers, 2);
	}

	.col-state {
		flex-grow: 1;
		flex-basis: 0%;
		justify-content: flex-end;
		margin-right: map-get($spacers, 2);
		text-align: right;
		margin-top: map-get($spacers, 1);
		margin-bottom: map-get($spacers, 1);
	}

	.col-lastUpdate {
		align-self: center;
		justify-content: flex-end;
		text-align: end;
		margin-left: map-get($spacers, 2);
		margin-right: map-get($spacers, 2);
	}
}

.area-blocking-spinner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: $z-index-area-blocking-spinner;
	height: 100%;
	color: gray;
	/* stylelint-disable-next-line sh-waqar/declaration-use-variable */
	font-size: 50px;
	background-color: $lighten-05;
}

.zoomOnClick {
	cursor: zoom-in;
}

ngb-typeahead-window.dropdown-menu {
	background: $white;
	max-height: 50vh;
	// stylelint-disable-next-line declaration-block-no-duplicate-properties
	max-height: 50svh;
	-webkit-overflow-scrolling: touch;
	overflow: auto;
	&:not(.body-typeahead) {
		min-width: 100%;

		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		// stylelint-disable-next-line declaration-no-important
		transform: translate(0, $form-control-min-height) !important;
	}

	&.body-typeahead .dropdown-item {
		padding: 0;
	}
}

.large:not(.badge) {
	// stylelint-disable-next-line declaration-no-important
	font-size: $font-size-lg-clamp !important;

	.is-mobile & {
		// stylelint-disable-next-line declaration-no-important
		font-size: $font-size-lg-clamp !important;
	}
}

.dropdown-on-header {
	container-type: inline-size;

	.dropdown-toggle {
		@container (max-width: 300px) {
			&::after {
				display: none;
			}
		}
	}

	.hide-on-container {
		@container (max-width: 300px) {
			display: none;
		}
	}
}

p-multi-select-checkbox {
	flex-shrink: 0;
}

.nav-link.btn-sm {
	padding: map-get($spacers, 1) map-get($spacers, 2);
}

.text-muted {
	@media (any-hover: hover) {
		.btn-outline-secondary:not(:disabled):hover > & {
			// stylelint-disable-next-line declaration-no-important
			color: $white !important;
		}
	}

	.btn-primary > & {
		// stylelint-disable-next-line declaration-no-important
		color: $white !important;
	}
}

@import "./plugin/_angular-editor.scss";

/* stylelint-disable-next-line no-descending-specificity */
p-input-shiftmodel-id .dropdown-item {
	padding: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	display: none;
}

input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
	-moz-appearance: textfield; /* stylelint-disable-line property-no-vendor-prefix */
}

.jasmine_html-reporter {
	position: relative;
	z-index: 1000000001;
}

.voda-bg-dark {
	background: url("../images/voda-bg-dark.png");
}

.voda-bg-light {
	background: url("../images/voda-bg-light.png");
}

.load-more-button {
	max-width: 350px;
	@include media-breakpoint-up(sm) {
		width: 50%;
	}
}

/* stylelint-disable-next-line no-descending-specificity */
.input-group-text {
	text-align: left;
}

.input-group-outline-dark {
	background-color: transparent;
	border-color: transparent;

	/* stylelint-disable selector-no-vendor-prefix */
	.form-control:-moz-placeholder {
		color: $gray-600;
	} /* Mozilla Firefox 4 to 18 */
	.form-control::-moz-placeholder {
		color: $gray-600;
	} /* Mozilla Firefox 19+ */
	.form-control::-webkit-input-placeholder {
		color: $gray-600;
	} /* WebKit, Blink, Edge */

	/* stylelint-disable-next-line no-descending-specificity */
	.form-control:-ms-input-placeholder {
		color: $gray-600;
	} /* Internet Explorer 10-11 */
	.form-control::-ms-input-placeholder {
		color: $gray-600;
	} /* Microsoft Edge */
	/* stylelint-enable */

	/* stylelint-disable-next-line no-descending-specificity */
	.input-group-text {
		// stylelint-disable-next-line declaration-no-important
		background-color: transparent !important;
	}
	/* stylelint-disable-next-line no-descending-specificity */
	input {
		background-color: transparent;
	}
	/* stylelint-disable-next-line no-descending-specificity */
	.input-group-text,
	input {
		// stylelint-disable-next-line declaration-no-important
		border-color: transparent !important;
		// stylelint-disable-next-line declaration-no-important
		background-color: $darken-03 !important;
		border-width: 0;
	}
}

.text-line-through {
	text-decoration: line-through;
}

@import "./plugin/_mat-stepper.scss";

.text-body-color {
	// stylelint-disable-next-line declaration-no-important
	color: $body-color !important;
}

// statuspage popup
iframe[src*="statuspage"] {
	// stylelint-disable-next-line declaration-no-important
	z-index: 10000000000 !important; // just to exceed Tawk.to's z-index
	opacity: 0.95;
}

.picker-calendar {
	padding: map-get($spacers, 3);
	@include media-breakpoint-up(xl) {
		padding-top: map-get($spacers, 3);
		padding-right: map-get($spacers, 3);
		padding-left: 0;
		padding-bottom: 0;
	}
}

.pIcon::before {
	display: inline-block;
}

.page-content {
	height: 100%;
	overflow: auto; // This is needed as otherwise on very small mobile devices, one can't scroll to get the entire content.
}

.form-text.text-warning {
	/* stylelint-disable-next-line declaration-no-important */
	color: darken($warning, 10%) !important;
}

footer {
	// NOTE: 	This needs to stay in global-styles.scss as long as we use bootstrap.
	// 				See PLANO-51764 for more details.
	/* stylelint-disable-next-line no-descending-specificity */
	a,
	a:not([href]) {
		display: inline-block;
		color: $light;

		@media (any-hover: hover) {
			&:hover {
				color: $link-hover-color;
				text-decoration: none;
			}
		}
	}
}

.scrolled-to-this-selector {
	&:not(.prevent-scroll-animation) {
		@include pulse-shadow($primary);

		// NOTE: If you change the times here, you might need to also adjust the animationDuration in PScrollToSelectorService
		animation:
			p-pulse 4s cubic-bezier(0.66, 0.33, 0, 1),
			p-scale 1.3s cubic-bezier(0.66, 0.33, 0, 1),
			p-after-glow 5s ease-in-out,
			p-z-index-animation 1.1s;
	}
}

@keyframes p-z-index-animation {
	0% {
		z-index: 1000;
	}
	99% {
		z-index: 1000;
	}
	100% {
	}
}

@keyframes p-after-glow {
	0% {
		outline: $primary solid 0;
		outline-offset: -1px;
	}
	50% {
		outline-width: 1px;
	}
	60% {
		outline-width: 1px;
	}
	80% {
		outline-width: 1px;
		outline-color: rgba(246, 154, 56, 0);
	}
	100% {
		outline-offset: -1px;
	}
}

@keyframes p-scale {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.05);
	}
	100% {
		transform: scale(1);
	}
}

#page-load-progress .ng-progress-bar .ng-bar {
	// stylelint-disable-next-line declaration-no-important
	height: 4px !important;

	@media (prefers-color-scheme: dark) {
		// stylelint-disable-next-line declaration-no-important
		height: 5px !important;
		// stylelint-disable-next-line declaration-no-important
		background-color: white !important;

		.ng-meteor {
			/* stylelint-disable declaration-no-important */
			box-shadow:
				white 0 0 10px,
				white 0 0 5px !important;
			/* stylelint-enable declaration-no-important */
		}
	}
}

// hide reCaptcha icon
.grecaptcha-badge {
	visibility: hidden;
}

@import "./../shared/core/p-common/p-anchor/p-anchor.directive.scss";

.gift-cards-list-headlines,
.gift-cards-list-tupel,
.transactions-list-headlines,
.transactions-list-tupel {
	// Dev’s need to take care that the UI for these elements is responsive enough that nothing unintendedly gets cropped.
	// This line makes the issues more obvious, as you will see scroll-bars in those cases.
	// Additionally it prevents cases where users are not able to reach some content.
	// But whenever you see scroll-bars here, you should fix the UI.
	// stylelint-disable-next-line declaration-no-important
	overflow: auto !important;
}

.max-lines-limit-2 {
	overflow: hidden;
	// stylelint-disable-next-line value-no-vendor-prefix
	display: -webkit-box;
	-webkit-line-clamp: 2; /* number of lines to show */
	line-clamp: 2;
	-webkit-box-orient: vertical;
}

// Google reviews widget styles
.es-header-container {
	// stylelint-disable-next-line declaration-no-important
	display: none !important;
}
.es-rating-value {
	// stylelint-disable-next-line declaration-no-important
	color: white !important;
}

.es-review-background-container {
	/* stylelint-disable-next-line declaration-property-unit-disallowed-list, declaration-no-important
 */
	border: solid 3px transparent !important;
	background-image: linear-gradient(white, white),
		conic-gradient(from 205deg, #fcbd03 25%, #e94435 25% 50%, #4285f4 50% 75%, #33a854 75%);
	background-origin: border-box;
	background-clip: padding-box, border-box;
}

.google-stars-wrapper .es-rating-bar-container {
	& > [class*="es-rating-stars-item-"] {
		opacity: 0;
	}
	&.animate > [class*="es-rating-stars-item-"] {
		animation: p-pop-in 0.8s ease-in-out forwards;
		&:nth-child(2) {
			animation-delay: 0.5s;
		}
		&:nth-child(3) {
			animation-delay: 1s;
		}
		&:nth-child(4) {
			animation-delay: 1.5s;
		}
		&:nth-child(5) {
			animation-delay: 2s;
		}
	}
}

@keyframes p-pop-in {
	0% {
		opacity: 0;
		transform: scale(0.8);
	}
	50% {
		opacity: 1;
		transform: scale(1.5);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}
