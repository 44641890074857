@import "functions";

[pEditable] .btn.pulse,
[pEditable] .card.pulse {
	// TODO: PLANO-169089 Can this be removed/moved to _buttons.scss?
	transition:
		border-color 0.3s ease-out,
		background-color 0.3s ease-out,
		color 0.3s ease-out;
}

// obsolete?
[pEditableInstantSaveOnClick] {
	display: block;
}

// TODO: PLANO-165618 Remove this
$p-editable-border-right-width: 5px;

.p-editable-active:not(.prevent-editable-active-border),
[pEditable].is-active [pEditableInstantSaveOnClick]:not(.prevent-editable-active-border),
[pEditable].is-active [pEditableTriggerFocussable]:not(.prevent-editable-active-border) {
	// TODO: PLANO-165618 Remove this
	// stylelint-disable-next-line declaration-no-important -- this will get removed anyway
	border-right-width: $p-editable-border-right-width !important;

	// On primary btns you would not see the contrast, which makes the text position look off
	&.btn-primary {
		border-right-color: shift-color-level($primary, 2);
	}
}

[pEditable].is-active [pEditableInstantSaveOnClick] [pEditableInstantSaveOnClick],
[pEditable].is-active [pEditableTriggerFocussable] [pEditableTriggerFocussable] {
	// stylelint-disable-next-line declaration-no-important
	border-right-width: 0 !important;
}

button[pEditableSuccessButton],
button[pEditableDismissButton] {
	color: $primary;

	--bs-btn-border-color: #{$gray-400};

	@media (any-hover: hover) {
		&:not([disabled], .disabled):hover {
			background: $primary;
			// stylelint-disable-next-line declaration-no-important
			color: $white !important;
		}
	}

	&:not([disabled]).show-animation {
		// stylelint-disable-next-line declaration-no-important
		background: $primary !important;
		// stylelint-disable-next-line declaration-no-important
		color: $white !important;
	}

	&.pulse-success {
		color: $success;

		@media (any-hover: hover) {
			&:not([disabled], .disabled):hover {
				background: $success;
				// stylelint-disable-next-line declaration-no-important
				color: $white !important;
			}
		}

		&:focus-visible,
		:not([disabled]).show-animation {
			// stylelint-disable-next-line declaration-no-important
			background: $success !important;
			// stylelint-disable-next-line declaration-no-important
			color: $white !important;
		}
	}
}

button[pEditableDismissButton] {
	&.show-animation fa-icon {
		// NOTE: To get the best result, take the same ms value here as in EditableDirective.animationDuration.
		rotate: -360deg;
		transition: rotate 700ms;
	}
}

button.pulse,
.pulse {
	position: relative;
	vertical-align: middle;

	&.show-animation {
		@include pulse-shadow($primary);
		&.pulse-success {
			@include pulse-shadow($success);
		}

		animation: p-pulse 1.25s cubic-bezier(0.66, 0.33, 0, 1);
		transform: translate3d(0, 0, 0);
		z-index: 1000;
	}
}

@keyframes p-pulse {
	to {
		box-shadow:
			0 0 0 12px transparent,
			0 0 0 24px rgba(246, 154, 56, 0);
	}
}
