@import "variables";

@mixin mark-on-colorful-bg {
	mark {
		/* stylelint-disable-next-line sh-waqar/declaration-use-variable -- We dont have such a small spacing in our variables and rarely need it. */
		padding: 0 0.15em 0.2em 0.15em;
		/* As the paddings are so tiny for this version, the normal border-radius (using our variables) with an rem value doesn't look good here. Instead, we need a more flexible value with em. */
		border-radius: 0.2em;
		color: $body-color;
		background-color: rgba(#fff, 0.85);
		mix-blend-mode: screen;
		// for more contrast and, therefore, better readability, the higher font-weight is needed.
		font-weight: $font-weight-semibold;
	}
}
@mixin code-on-colorful-bg {
	code {
		color: inherit;
		background-color: $darken-015;
	}
}
@mixin link-on-colorful-bg {
	.link,
	a {
		color: inherit;
		text-decoration: underline;
		transition: text-underline-offset 0.2s ease-in-out;
		@media (any-hover: hover) {
			&:hover {
				color: inherit;
				/*
				 * Our default/global offset is 0.15em. See _typography.scss.
				 */
				text-underline-offset: 0.4em;
			}
		}
	}
}
@mixin headline-on-colorful-bg {
	h1,
	.h1,
	h2,
	.h2,
	h3,
	.h3,
	h4,
	.h4,
	h5,
	.h5,
	h6,
	.h6 {
		color: inherit;
	}
}

@mixin typography-on-colorful-bg {
	@include mark-on-colorful-bg();
	@include code-on-colorful-bg();
	@include link-on-colorful-bg();
	@include headline-on-colorful-bg();
}
