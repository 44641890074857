.anchor-directive {
	opacity: 0;
	transition:
		transform cubic-bezier(1, 0, 0.17, 1) 0.3s,
		opacity cubic-bezier(1, 0, 0.17, 1) 0.3s;

	&.btn.outside,
	&.btn.outside:hover {
		// stylelint-disable-next-line declaration-no-important
		background-color: transparent !important;
		// stylelint-disable-next-line declaration-no-important
		border-color: transparent !important;
		padding-block: 0; // this ensures better alignment for items with a small height: https://www.awesomescreenshot.com/video/29277526?key=751ee793b4d770a87e72c61b620776de
	}

	&.bottom {
		bottom: 0;
	}
	&.top {
		top: 0;
	}
	&.center {
		top: 50%;
	}
	&.right {
		right: 0;
	}

	&.inside {
		&.center {
			transform: translateY(-50%);
		}
		&.shown {
			opacity: 60%;

			&.center {
				opacity: 60%;
				transform: translateY(-50%);
			}
		}
	}

	&.outside {
		transform: translateX(75%);
		&.shown {
			transform: translateX(100%);
			opacity: 60%;
		}
		&.center {
			transform: translateX(75%) translateY(-50%);
			&.shown {
				transform: translateX(100%) translateY(-50%);
			}
		}
	}

	@media (any-hover: hover) {
		&.shown:hover {
			/* stylelint-disable-next-line declaration-no-important */
			opacity: 100% !important;
		}
	}
}
